<template>

  <div id="flightsGadget" class="min-h-58 lg:min-h-26 mb-1 p-2 w-full bg-secondary">
    <div id="kayakFlightsWidget"
          data-affiliate-id="travelsupermarketuwl"
          :data-url-prefix="dataUrlPrefix"
          data-country-code="gb"
          data-theme="dark"
          data-language-code="en"
          data-currency-code="GBP"
          data-disable-compare-to="true"
          :data-location-id="dataLocationId"
          :data-click-id="getDataClickId"
          :data-fill-trip-type="getDataFillTripType"
          :data-fill-location-from="getDataFillLocationFrom"
    />
  </div>

</template> 

<script>
import HelperFunctions from "@/HelperFunctions"

const TYPE_ONE_WAY = '1'
const TYPE_RETURN = '2'
const DATA_URL_PREFIX_PAGE_ADDRESS = '/en-gb/flights/results/#'
const PAGE_LANG = 'en-gb'

export default {
    name: 'FlightsGadget',
    components: {},
    data() {
      return {
        // Ensure that the final URL path is the same as the div id that references the script
        kayakScriptLocation: 'https://flights-en.travelsupermarket.com/search-widget/script/uwl/kayakFlightsWidget',
        webSessionId: '',
        dataLocationId: 'home_page',
        dataUrlPrefix: 'https://travelsupermarket-uwl.affiliate.kayak.com'
      };
    },
    props: {
        data: Object,
    },
    computed: {
      getDataFillLocationFrom() {
          if(!this.data || this.data.useIpGeoForDepart === undefined || this.data.useIpGeoForDepart) {
            return 'GEOIP'
          } else {
            return ''
          }
      },
      getDataFillTripType() {
          if (!this.data || this.data.defaultToOneWay === undefined || !this.data.defaultToOneWay) {
              return TYPE_RETURN
          } else {
              return TYPE_ONE_WAY
          }
      },
      getDataClickId() {
          return this.webSessionId
      }
    },
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', this.kayakScriptLocation)
      document.head.appendChild(recaptchaScript)
      this.webSessionId = HelperFunctions.readCookie('web-session-id')
      
      //Code there to investigate missing ids in the kayak report. It will need to be removed once resolved
      if(this.webSessionId == null || this.webSessionId.length < 1) {
        this.webSessionId = "unavailable"
      }

      this.dataUrlPrefix = `${window.location.protocol}//${window.location.host}${DATA_URL_PREFIX_PAGE_ADDRESS}`
      if (location.pathname.includes(PAGE_LANG)) {
        let siteLoc = location.pathname.split(PAGE_LANG)[1]
        siteLoc = siteLoc.replace(/\/$/, '').replace(/^\//, '')
        this.dataLocationId = siteLoc.replaceAll('/', '_').replaceAll('-', '_')
      }
    }
}
</script>